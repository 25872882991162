#primary-nav {

    /* General Menu Styles */
    ul, li {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .dropdown-menu,
    [data-toggle="dropdown"] {
        display: none;
    }

    /* Mobile Menu Toggle */
    @include media-breakpoint-down(md) {

        .nav-item.dropdown {
            position: relative;

            > .nav-link {
                padding-right: 20px;
            }

            > [data-toggle="dropdown"] {
                position: absolute;
                top: 9px;
                right: -8px;
                display: block;
                width: 40px;
                height: 30px;
                cursor: pointer;
                line-height: 30px;
                text-align: center;
                color: $color-orange;
                font-size: 24px;
                border-left: 1px solid rgba(105, 111, 119, 0.15);

                &.open-section {
                    display: block;
                }

                &.close-section {
                    display: none;
                }
            }

            &.open > [data-toggle="dropdown"] {

                &.open-section {
                    display: none;
                }

                &.close-section {
                    display: block;

                    &:before {
                        display: block;
                        transform: rotate(45deg);
                    }
                }
            }
        }
    }

    /* Mobile Menu Styles */
    @include media-breakpoint-down(md) {
        position: relative;
        margin: 0 -5px;
        background: #fff;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);

        &:before {
            position: absolute;
            top: -20px;
            right: 29px;
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 10px;
            border-color: transparent transparent #fff transparent;
            opacity: 0;
        }

        &.show:before {
            opacity: 1;
        }

        .nav-link {
            padding: 5px 15px;
        }

        .open > .dropdown-menu {
            display: block;
        }

        .menu {

            a {

            }

            // First Level
            > li {
                margin: 0 20px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.03);

                > a {
                    padding: 14px 0;
                    color: $color-blue;
                    font-family: $typekit-montserrat;
                    font-size: 18px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 20px;
                }

                // Second Level
                &.open > .dropdown-menu {
                    margin-left: 20px;
                    background: transparent;
                    border: none;

                    > li {
                        list-style: initial;

                        a {
                            padding: 13px 0;
                            margin: 0;
                            color: $color-blue;
                            font-size: 15px;
                            letter-spacing: 0.5px;
                            line-height: 15px;
                        }

                        // Third Level
                        &.open > .dropdown-menu {

                            > li {

                                a {
                                    padding-left: 45px;
                                }
                            }
                        }
                    }
                }
            }
        }

        /* Close Mobile Menu */
        .navbar-toggle-close {
            margin-bottom: -.5rem;
            padding: 10px;
            width: 100%;
            border: none;
            background: none;
            text-align: center;
            cursor: pointer;
            color: $white;
            border-top: 1px solid $white;

            &:hover {
                top: 0;
            }
        }
    }

    // Desktop Menu Styles
    @include media-breakpoint-up(lg) {

        ul {

            li {
                margin-right: 60px;

                &:last-child {
                    margin-right: 0;
                }

                a {
                    display: block;
                    height: 40px;
                    padding: 0;
                    color: #fff;
                    font-family: $typekit-montserrat;
                    font-size: 18px;
                    font-weight: bold;
                    letter-spacing: 0.5px;
                    line-height: 26px;
                    text-transform: uppercase;

                    &:after {
                        content: '';
                        display: block;
                        margin-top: 10px;
                        width: 100%;
                        height: 4px;
                        border-top: 1px solid #E84A27;
                        border-bottom: 1px solid #E84A27;
                        opacity: 0;
                        transition: margin-top 0.3s ease-in-out, opacity 0.3s ease-in-out;
                    }

                    &:hover {
                        color: #fff;

                        &:after {
                            content: '';
                            display: block;
                            margin-top: 0;
                            width: 100%;
                            height: 4px;
                            border-top: 1px solid #E84A27;
                            border-bottom: 1px solid #E84A27;
                            opacity: 1;
                        }
                    }
                }

                &.current-menu-parent > a,
                &.current-menu-item > a,
                &.current-menu-ancestor > a {
                    &:after {
                        content: '';
                        display: block;
                        margin-top: 0;
                        width: 100%;
                        height: 4px;
                        border-top: 1px solid #E84A27;
                        border-bottom: 1px solid #E84A27;
                        opacity: 1;
                    }
                }
            }
        }

        .search.button.desktop {
            display: block;
            padding: 0;
            margin: 0 0 0 15px;
            background: transparent;
            border: 0;
            color: $white;
            box-shadow: none;

            &:hover {
                color: $alt-link-color;
                top: 0;
            }
        }
    }
}
