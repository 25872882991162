.site-footer {

    &__top {
        padding: 50px 0;
        background: $color-blue;

        @include media-breakpoint-up(md) {
            padding: 100px 0;
        }

        h3,
        p {
            color: #fff;
        }

        .col--social,
        .col--menu,
        .col--contact {
            @include media-breakpoint-down(md) {
                text-align: center;
            }
        }

        .col--social {
            @include media-breakpoint-down(md) {

                &:after {
                    margin-top: 60px;
                    margin-bottom: 60px;
                    content: '';
                    display: block;
                    height: 1px;
                    background-color: #060D17;
                }

                p {
                    font-size: 16px;
                    line-height: 25px;
                }
            }
        }

        .col--menu {

            .menu {
                margin: 0;
                padding: 0;

                li {
                    position: relative;
                    display: block;
                    margin: 0 0 26px;
                    padding: 0;
                    list-style: none;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    a {
                        display: block;
                        color: $color-orange;
                        font-family: $typekit-montserrat;
                        font-weight: normal;
                        font-size: 18px;
                        letter-spacing: 0;
                        line-height: 22px;

                        @include media-breakpoint-up(lg) {
                            font-size: 16px;
                        }
                    }
                }

                &--primary li a {
                    font-weight: bold;
                }
            }

            + .col--menu {
                @include media-breakpoint-down(md) {
                    margin-top: 26px;

                    &:after {
                        margin-top: 60px;
                        margin-bottom: 60px;
                        content: '';
                        display: block;
                        height: 1px;
                        background-color: #060D17;
                    }

                }
            }
        }

        // Social Media
        ul.social {
            margin: 30px 0 0 0;
            padding: 0;

            @include media-breakpoint-down(md) {
                margin-left: auto;
                margin-right: auto;
            }

            li {
                display: inline-block;
                list-style: none;
                margin: 0 25px 0 0;
                padding: 0;

                &:last-child {
                    margin-right: 0;
                }

                a {
                    display: block;
                    font-size: 24px;
                    font-weight: 900;
                    color: $color-orange;

                    i {
                        font-family: $fontAwesomeBrands;
                    }

                    &:hover {
                        color: $primary-link-color;
                    }
                }
            }
        }

        .col--contact {

            img {
                width: 100%;
                height: auto;
            }

            .contact-top {
                margin-bottom: 42px;

                @include media-breakpoint-up(lg) {
                    margin-bottom: 35px;
                }

                img {
                    margin-bottom: 13px;
                    max-width: 300px;
                }

                p {
                    font-size: 16px;
                    line-height: 22px;
                }
            }

            .contact-bottom {

                img {
                    margin-bottom: 10px;
                    max-width: 150px;
                }

                p {
                    font-size: 12px;
                    line-height: 15px;
                }
            }

            .contact-top,
            .contact-bottom {
                p {
                    @include media-breakpoint-down(md) {
                        font-size: 12px;
                        line-height: 18px;
                    }
                }
            }
        }
    }

    &__bottom {
        background-color: #10213D;

        // Copyright Menu
        .copyright {
            padding-top: 20px;
            padding-bottom: 20px;
            color: rgba(255,255,255,0.6);
            font-family: $typekit-montserrat;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 15px;

            @include media-breakpoint-up(md) {
                padding-top: 12px;
                padding-bottom: 12px;
            }

            .blog-info {
                display: block;
                margin-bottom: 0;
                vertical-align: middle;

                @include media-breakpoint-up(md) {
                    display: inline-block;
                }

                @include media-breakpoint-down(sm) {
                    text-align: center;
                }
            }

            &-menu {
                display: block;
                margin-bottom: 0;
                vertical-align: middle;

                @include media-breakpoint-up(md) {
                    display: inline-block;
                }

                ul {
                    margin: 0;
                    padding: 0;
                    list-style-type: none;

                    @include media-breakpoint-down(sm) {
                        margin-left: auto;
                        margin-right: auto;
                        text-align: center;
                    }

                    li {
                        display: inline-block;
                        padding: 0;
                        margin: 0;
                        //margin-right: 15px;

                        &:before {
                            content: '•';
                            margin: 0 8px 0 4px;
                            display: inline-block;
                        }

                        @include media-breakpoint-down(sm) {
                            &:first-of-type {
                                &:before {
                                    display: none;
                                }
                            }
                        }

                        &:last-child {
                            margin-right: 0;
                        }

                        a {
                            color: rgba(255,255,255,0.6);
                            font-family: $typekit-montserrat;
                            font-size: 12px;
                            letter-spacing: 0;
                            line-height: 15px;
                        }
                    }
                }
            }
        }

    }

    .back-to-top {
        display: none;
    }
}
