body.has-hero {
    .site-header {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 10;
    }
}

#taxSchoolCartWidget {
    display: flex;

    @include media-breakpoint-down(md) {
        margin-left: auto;
    }

    a {
        height: 34px;
    }

    span {
        a:before {
            margin-right: 4px;
            font-family: $fontAwesome;
            font-weight: bold;
            color: #fff;
            transition: color 0.3ms ease-in-out;
        }

        &.account a:before {
            content: '\f007';
        }

        &.cart a:before {
            content: '\f07a';
        }
    }

    span {
        &.total {
            padding: 9px 0 9px 10px;
            color: #FFFFFF;
            font-family: $typekit-montserrat;
            font-size: 13px;
            font-weight: 500;
            letter-spacing: 0.25px;
            line-height: 16px;
        }

        &.account,
        &.cart {
            a {
                display: block;
                padding: 9px 10px;
                color: #FFFFFF;
                font-family: $typekit-montserrat;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0.25px;
                line-height: 16px;

                @include media-breakpoint-up(lg) {
                    font-size: 13px;
                }

                &:hover {
                    background: #fff;
                    color: $color-blue;
                    text-decoration: none;

                    &:before {
                        color: $color-blue;
                    }
                }
            }
        }
    }

}

.site-header {

    &__top {
        background: $color-orange;

        ul {
            @extend .d-none;
            @extend .d-lg-flex;
            flex-grow: 1;
            padding: 0;
            margin: 0 0 0 -10px;

            li {
                display: inline-block;
                margin: 0;
                padding: 0;

                a {
                    display: block;
                    padding: 9px 10px;
                    height: 100%;
                    color: #FFFFFF;
                    font-family: $typekit-montserrat;
                    font-size: 13px;
                    font-weight: 500;
                    letter-spacing: 0.25px;
                    line-height: 16px;

                    &:hover {
                        background: #fff;
                        color: $color-blue;
                        text-decoration: none;
                    }
                }

                &.current-menu-parent,
                &.current-menu-item,
                &.current_page_item,
                &.current_page_parent,
                &.active {
                    position: relative;

                    &:after {
                        position: absolute;
                        bottom: -14px;
                        left: calc(50% - 8px);
                        content: '';
                        display: block;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 7px;
                        border-color: #fff transparent transparent transparent;
                    }

                    a {
                        background: #fff;
                        color: $color-blue;
                        font-weight: bold;
                    }
                }
            }
        }

    }

    &__bottom {

        .navbar {

            &-brand {
                padding: 25px 0;
                flex: 0 1 130px;
                max-width: 130px;

                @include media-breakpoint-up(md) {
                    padding: 30px 0;
                    flex: 0 1 232px;
                    max-width: 232px;
                }
            }

            .navbar-toggle-container {

                .search.button.mobile {
                    box-shadow: none;
                    vertical-align: middle;
                    margin: 0 15px 0 0;
                    display: inline-block;
                    padding: 0;
                    background: transparent;
                    border: 0;
                    color: white;

                    &:hover {
                        color: $alt-link-color;
                        top: 0;
                    }

                    @include media-breakpoint-up(lg) {
                        display: none;
                    }
                }

                .navbar-toggler {
                    display: inline-block;
                    //margin-right: -12px;
                    background: none !important;
                    border: none !important;
                    outline: none !important;
                    box-shadow: none !important;

                    @include media-breakpoint-up(lg) {
                        display: none;
                    }

                    > span {
                        display: block;
                        width: 40px;
                        height: 3px;
                        margin: 6px 0;
                        background-color: #fff;
                        transition: all .2s ease-in-out;

                        &:before {
                            position: relative;
                            top: -8px;
                        }

                        &:before,
                        &:after {
                            display: block;
                            margin: 5px 0;
                            content: '';
                            width: 40px;
                            height: 3px;
                            background-color: #fff;
                            transition: all .2s ease-in-out;
                        }
                    }
                }

                .navbar-toggler:not(.collapsed) {

                    span {
                        background: transparent;

                        &:before {
                            position: static;
                            display: block;
                            transform: rotate(135deg);
                        }

                        &:after {
                            display: block;
                            transform: translateY(-8px) rotate(-135deg);
                        }
                    }
                }

            }
        }

        #primary-nav {
            // styles for this element are in _primary-menu.scss
        }
    }
}
