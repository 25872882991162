@import "override_vars";
@import "oms_mixins";
// Bootstrap.
@import "../bootstrap/scss/functions";
@import "../bootstrap/scss/variables";
@import "../bootstrap/scss/mixins";
@import "../bootstrap/scss/root";
@import "../bootstrap/scss/buttons";
@import "../bootstrap/scss/tables";
@import "vars";
@import "override_styles";

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body {
    font-size: 18px;
    line-height: 32px;

    @include media-breakpoint-down(md) {
        font-size: 16px;
        line-height: 26px;
    }

}

body {
    position: relative;
}

/*------------------------------*\
        Global
\*------------------------------*/

h1, h2, h3, h4, h5, .intro-text {
    letter-spacing: -1px;
    color: $headings-color;
}

h1, h2, h3, h4, h5, h6 {
    a:after {
        display: inline;
        content: '›';
        margin-left: 8px;
    }
}

h1,
.h1,
.editor-post-title__input {
    @include double_line();
    font-weight: 700;
    font-size: 60px;
    line-height: 1.083333333;
    letter-spacing: -1px;

    @include media-breakpoint-down(md) {
        font-size: 38px;
        line-height: 1.184210526;
    }
}

h2,
.h2 {
    font-weight: 700;
    font-size: 45px;
    line-height: 1.111111111;
    letter-spacing: -0.5px;

    &:not(.no-underline) {
        @include double_line();
    }

    @include media-breakpoint-down(md) {
        font-size: 28px;
        letter-spacing: 1.214285714;
    }

    &:after {
        border-top-width: 1px;
        border-bottom-width: 1px;
        height: 4px;
        width: 50px;
    }
}

h3,
.h3 {
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 30px;
    line-height: 1.333333333;

    @include media-breakpoint-down(md) {
        font-size: 24px;
    }

    a.external::after {
        margin-left: 7px;
    }

    &.i-logo::before {
        top: 2px !important;
    }
}

h4,
.h4 {
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.25;
}

// H4 Replacement.
.intro-text {
    margin-bottom: 20px;
    font-family: $typekit-source-serif-pro;
    font-weight: 300;
    font-size: 24px;
    line-height: 1.583333333;
    letter-spacing: -0.25px;
    color: $color-mid-gray;

    @include media-breakpoint-down(md) {
        font-size: 20px;
        line-height: 1.5;
    }
}

h5,
.h5 {
}

h6,
.h6 {
    color: $color-mid-gray;
    font-family: $typekit-montserrat;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 2px;
    line-height: 20px;
    text-transform: uppercase;
}

a {
    color: $link-color;
    text-decoration: none;
    transition: all 0.3s ease;

    &:hover {
        color: $alt-link-color;
    }

    img {
        border: none;
    }

    &.cta {
        font-family: $typekit-montserrat;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 2px;
        line-height: 20px;
        text-transform: uppercase;
        color: $color-orange;
        border-bottom: 1px solid $color-blue;

        &:after {
            display: inline;
            margin-left: 8px;
            content: '\0203A';
            color: $color-blue;
        }

        &:hover {
            text-decoration: none;
            color: $color-blue;
        }
    }

    &.external {
        font-weight: bold;

        &:after {
            margin-left: 1px;
            content: '\f064';
            display: inline;
            font-family: $fontAwesome;
            font-weight: bold;
        }
    }
}

h3, p {
    &.i-logo {
        &:before {
            content: '';
            display: inline-block;
            width: 17px;
            height: 24px;
            background-image: url(../images/Icon-UofI-I.png);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            position: relative;
            top: 5px;
            margin-right: 6px;
        }
    }
}

img {
    vertical-align: top;

    &.img--circle {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        border: 10px solid rgba(255, 255, 255, 0.15);
    }
}

hr {
    background-color: rgba(105, 111, 119, 0.15);
    border: none;
    width: 100%;
    height: 1px;
    overflow: hidden;
    margin: 30px 0;
    padding: 0;
}

p {
    margin-bottom: 20px;

    &:last-of-type {
        margin-bottom: 0;
    }
}

blockquote {
    margin-bottom: 45px;
    padding: 45px;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
    border: 1px solid #C1CFDA;
    background: #F4F5F7;

    p {
        font-size: 24px;
        font-style: italic;
        letter-spacing: 0;
        line-height: 34px;
    }
}

ul,
ol {
    margin: 15px 0 20px 25px;
    padding: 0;
    font-size: 16px;
    line-height: 26px;

    li {
        margin: 0 0 10px;
        padding: 0 0 0 12px;
    }
}

ul li {
    list-style: disc outside none;

    &::marker {
        font-size: 12px;
    }
}

ol, ul {
    &.list--alpha-upper {
        list-style-type: upper-alpha;
    }
    &.list--alpha-lower {
        list-style-type: lower-alpha;
    }

    &.list--roman-upper {
        list-style-type: upper-roman;
    }
    &.list--roman-lower {
        list-style-type: lower-roman;
    }

    &.list--disc-solid {
        list-style-type: disc;
    }
    &.list--disc-open {
        list-style-type: circle;
    }

    &.list--diamond > li {
        position: relative;
        list-style: none;
        
        &:before {
            position: absolute;
            left: -15px;
            display: inline;
            content: '\2666';
        }
    }
}

// Buttons.
a.button,
input[type="submit"].button,
input[type="submit"].btn-primary,
input[type="image"].button,
button,
div.woocommerce a.button,
.woocommerce a.button.alt {
    @extend .btn;
    @extend .btn-primary;
    text-transform: uppercase;
    letter-spacing: 1px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background: linear-gradient(180deg, #F24D29 0%, #E84A27 100%);

    &:focus,
    &:active {
        outline: none !important;
        border: none !important;
    }
}

.btn {
    font-size: 15px !important;
    text-transform: uppercase;
    white-space: normal;
}

.btn-secondary {
    background: linear-gradient(180deg, #193561 0%, #13294B 99.92%);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &.btn-plus:after {
        background: linear-gradient(285deg, rgba(0, 0, 0, 0.25) 50%, rgba(0, 0, 0, 0) 50%);
    }
}

// Client-defined button style
.btn.btn-tax-school {
    background-image: none;
    background-color: #E84A27;
    color: #ffffff;
    border: 1px solid transparent;
    box-shadow: 2px 2px 2px #ccc;
    padding: 11px 15px;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 12px;
    text-transform: uppercase;
    border-radius: 0.2rem;
    transition: none !important;

    &:hover {
        color: #000000;
        background-color: #e6e6e6;
        border: 1px solid #898989;
        transition: none !important;
    }
}

.btn.btn-text {
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 0;
    color: $color-orange;

    &:after {
        display: inline-block;
        margin-left: 8px;
        content: '\0203A';
        color: $color-blue;
    }

    &:hover {
        color: $color-blue;
    }

    &:focus {
        outline: none;
    }
}

.btn-plus {
    position: relative;
    padding-right: 50px;

    @include media-breakpoint-down(sm) {
        text-align: left;
    }

    &:before {
        position: absolute;
        top: calc(50% - 10px);
        right: 11px;
        display: block;
        width: 70px;
        content: '\f067';
        font-family: $fontAwesome;
        font-size: 15px;
        color: #fff;
        text-align: right;
    }

    &:after {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        display: block;
        width: 70px;
        padding: 0;
        background: linear-gradient(285deg, rgba(0, 0, 0, 0.08) 50%, rgba(0, 0, 0, 0) 50%);
        content: '';
    }
}

.btn + .btn {
    margin-left: 20px;
}

.table-responsive > table,
table {
    @extend .table;
    border: 1px solid $color-dark-gray;

    th, td {
        border-top: none;
        padding: 10px 20px;
        font-family: $typekit-montserrat;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 20px;
        vertical-align: top;

        + th,
        + td {
            border-left: 1px solid $color-dark-gray;
        }
    }

    thead {

        tr {
            background-color: $color-mid-gray;
        }

        th, td {
            border-bottom: none;
            text-transform: uppercase;
            color: #FFFFFF;
            font-weight: bold;
        }
    }

    tbody {

        th, td {
        }

        tr:nth-child(even) {
            background-color: $color-light-gray;
        }
    }
}
