// For OMS Header Footer Feed plugin.
@import "override_vars";
@import "../bootstrap/scss/functions";
@import "../bootstrap/scss/variables";
@import "../bootstrap/scss/mixins";
@import "../bootstrap/scss/reboot";

@import "vars";
@import "overrides";
@import "override_styles";
@import "oms_mixins";

@import "../bootstrap/scss/images";
@import "../bootstrap/scss/type";
@import "../bootstrap/scss/grid";
@import "../bootstrap/scss/nav";
@import "../bootstrap/scss/navbar";
@import "../bootstrap/scss/buttons";

@import "../bootstrap/scss/utilities";

html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-size: 15px;
}

.site-header--static,
.site-content--static,
.site-footer--static {
    @import "default";
}

.site-header--static {
    @import "layout/header";
    @import "layout/primary-menu";

    .site-header__bottom {
        background: $color-blue;
    }

    .secure-checkout {
        margin-left: auto;
        font-family: $typekit-montserrat;
        font-weight: bold;
        font-size: 12px ;
        color: #fff;
        text-transform: uppercase;

        @include media-breakpoint-up(sm) {
            font-size: 22px ;
        }

        img {
            @include media-breakpoint-only(xs) {
                margin-top: 1px;
                width: 15px;
            }

            @include media-breakpoint-up(sm) {
                margin-top: 4px;
            }
        }
    }
}

.site-content--static {
    padding-top: 68px;
    padding-bottom: 125px;

    h1 {
        margin-bottom: 20px;
        font-family: $typekit-source-serif-pro;
        font-size: 45px;
        font-weight: bold;
        letter-spacing: -0.5px;
        line-height: 50px;

        &:after {
            display: none !important;
        }
    }

    .btn-sm {
        padding: 11px 15px !important;
        font-size: 12px !important;
        letter-spacing: 0.5px;
        line-height: 12px;
    }

    table {
        input[type=submit].btn-primary,
        .btn-primary {
            border: none;
            border-radius: 0 !important;
            padding: 5px 10px !important;
            color: #FFFFFF;
            font-family: $typekit-montserrat;
            font-size: 10px !important;
            font-weight: bold;
            letter-spacing: 2px;
            line-height: 10px;
            text-transform: uppercase;
        }
    }

    .cart-codes {
        margin-bottom: 30px;

        .code-type + .code-type {
            margin-top: 16px;
        }

        label {
            margin: 0;
        }

        input {
            margin: 0 0 5px 0;
            height: 34px;
            border: 1px solid #E3E3E1;
            border-radius: 5px;
            background-color: #FFFFFF;
            box-shadow: inset 1px 1px 5px rgba(0,0,0,0.19);

            @include media-breakpoint-up(sm) {
                margin: 0 30px;
            }
        }
    }

    .cart-total {
        color: #696F77;
        font-family: $typekit-montserrat;
        font-size: 22px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 20px;
    }

    .cart-actions {
        margin-top: 30px;

        button + button {
            margin-left: 30px;
        }
    }
}

.site-footer--static {
    @import "layout/footer";
}

