.container-fluid {
    max-width: 1400px;
    padding-left: 20px !important;
    padding-right: 20px !important;

    @include media-breakpoint-up(lg) {
        padding-left: 50px !important;
        padding-right: 50px !important;
    }
}

img {
    @extend .img-fluid;
}
