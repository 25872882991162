@mixin headline_font() {
    font-family: $typekit-source-serif-pro;
    font-weight: 400;
    font-style: normal;
}

@mixin body_font() {
    font-family: $typekit-montserrat;
    font-weight: 400;
    font-style: normal;
}

@mixin double_line() {
    &:after {
        content: '';
        display: block;
        margin-top: 15px;
        width: 40px;
        height: 11px;
        border-top: 3px solid $color-orange;
        border-bottom: 3px solid $color-orange;
    }
}

// Used for text, email, tel, password, and textarea.
@mixin input_styles() {
    padding: 14px 25px;
    font-size: 16px;
    line-height: 22px;
    background: #fff;
    border: none;
    border-radius: 30px;
    box-shadow: 0 0 6px rgba(0,0,0,.1);

    &::placeholder {
        font-style: italic;
        color: $color-gray-3;
    }
}

// Requires select to be contained within a wrapping element.
@mixin select_styles() {
    position: relative;
    display: block;

    &:after {
        content: '❯';
        display: block;
        position: absolute;
        top: 14px;
        right: 30px;
        color: $color-orange-1;
        z-index: 10;
        pointer-events: none;
        font-size: 20px;
        transform: rotate(90deg);
    }

    select {
        background: white;
        padding: 16px 20px;
        font-size: 16px;
        line-height: 22px;
        border-radius: 30px;
        border: none;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
}

@mixin label_style() {
    color: $color-gray-4;
    font-family: $typekit-open-sans;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
}

@mixin fontColors($color: false) {
    @if $color != false {
        p, ul, li, h1, h2, h3, h4, h5, h6 {
            color: $color;
        }
    }
}

