// SITE COLOR VALUES
$bg-color: #222;
$bg-color-dark: rgba(0,0,0,.32);
$body-text-color: #666;
$heading-color: #333;
$primary-link-color: #0171ac;
$alt-link-color: var(--secondary);
$blockquote: #c44e38;
$white: #fff;

// Fonts
$headingFont: "proxima-nova", sans-serif;
$bodyFont: "proxima-nova", sans-serif;
$fontAwesome: "Font Awesome 5 Free", sans-serif;
$fontAwesomeBrands: "Font Awesome 5 Brands", sans-serif;


span[class^="fa-"], span[class*=" fa-"] {
    font-family: $fontAwesome;
}
i.fab {
    font-family: $fontAwesomeBrands;
}

@mixin webfont($weight: 400) {
    font-family: $headingFont;
    font-weight: $weight;
}

%button {
    //display: inline-block;
    //margin-top: 5px;
    //text-decoration: none;
    //padding: 10px 20px;
    //font-weight: 700;
    //box-shadow: 0 5px 5px 0px rgba(0,0,0,.1);
    //position: relative;
    //top: 0;
    //transition: all .3s ease;
    //
    //&:hover {
    //    box-shadow: 0 10px 10px 0px rgba(0,0,0,.2);
    //    top: -2px;
    //    cursor: pointer;
    //}
}

.btn-primary {
    //@extend %button;
    //background: $primary-link-color;
    //color: #fff;
    //
    //&:hover {
    //    background: #0074b3;
    //}
}
.btn-secondary {
    //@extend %button;
    //background: #fff;
    //color: $heading-color;
    //
    //&:hover {
    //    color: $heading-color;
    //    background: #e5e5e5;
    //}
}

%appendSingleArrow {
    &:after {
        content: "\00a0\203a";
    }
}

%appendDoubleArrow {
    &:after {
        content: "\00a0\f101";
        font-family: $fontAwesome;
    }
}

%prependDoubleArrow {
    &:before {
        content: "\F100\00a0";
        font-family: $fontAwesome;
    }
}

%prependSingleArrow {
    &:before {
        content: "\2039\00a0";
    }
}

/* ======================================== */
/* Mixins
/* ======================================== */

@mixin input-placeholder {
    &.placeholder {
        @content;
    }
    
    &:-moz-placeholder {
        @content;
    }
    
    &::-moz-placeholder {
        @content;
    }
    
    &:-ms-input-placeholder {
        @content;
    }
    
    &::-webkit-input-placeholder {
        @content;
    }
}

@mixin appendSingleArrow {
    &:after {
        content: "\00a0\203a";
    }
}

@function toem($photoshop) {
    @return #{$photoshop / 1000}em;
}
